.Stream {
  margin: 0 auto;
  background: none;
  border-radius: 1px;
  position: relative;
}

.Stream-Video {
  margin: 0 auto;
  background-color: #F5FAFD;
  border-radius: 1px;
  position: relative;
}

.Stream-Play {
  aspect-ratio: 480 / 480;
  position: relative;
  background-color: #ccc;
  border-radius: 1px;
}

.Stream-Play-Local {
  position: absolute;
  height: 20vw;    /* 64px; */
  width: 20vw;     /* 64px; */
  bottom: 80px;
  right: 12px;
  background-color: #fff;
}

.Stream-Play-Remote {
  aspect-ratio: 480 / 480;
  position: relative;
  background-color: #000;
}

.Stream-Controls {
  position: absolute;
  bottom: 0px;
  width: 100%; 
  text-align: center;
  background: rgba(57, 57, 57, 0.8);
  z-index:90
}

.Stream-state {
  color: #fff;     /* #141e55; */
  font-size: clamp(1rem, 2vw, 2rem);
  font-weight: 400;
  line-height: 1.5;
}

.Stream-Button {
  background-color: #141e55 !important;
  color: #fff !important;
  border-radius: 10px !important;
  font-size: clamp(1rem, 2vw, 2rem) !important;
  font-weight: 500 !important;
  margin: 0px 0px 10px 0px !important; 
  padding: 10px 15px 10px 15px !important;
  z-index:100
}

.Stop-Stream-Button {
  background-color: red !important; 
  color: #fff !important;
  border-radius: 10px !important;
  font-size: clamp(1rem, 2vw, 2rem) !important;
  font-weight: 500 !important;
  margin: 0px 0px 10px 0px !important; 
  padding: 10px 15px 10px 15px !important;
  z-index:100
}

.Stream-NoStream {
  color: #ababab;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.7px;
  margin: 0 auto;
  top: 50%;
  position: relative;
  width: 100%;
  text-align: center;
}

.Stream-MutedStream {
  margin: 0 auto;
  top: calc(50% - 120px);            /** moved text up to accomodate the line breaks **/
  position: absolute;
  width: 100%;
  text-align: center;
}

.Stream-MutedStreamMedia {
  margin: 0 auto;
  position: absolute;
  width: 100%;
  text-align: center;
}

.Stream-MutedStream-text {
  height: 40px;
  line-height: 40px;
  color: #000;
  background-color: #ccc;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 500;
  margin: 0 auto;
  padding: 12px;
  border-radius: 1px;
  white-space: pre-line;            /** required to accept /n line breaks **/
}

.Stream-PublicAreaVisible {
/*  height: 100%; */
}

.Stream-PublicAreaHidden {
  display: none;
}
