.Viewer {
  height: calc(100%);
  padding-top: 40px;
}

.Viewer-background {
  background-image: url('background.jpeg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 180px;
}

.Viewer-title {
  color: white;
  width: 90%;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  position: relative;
  transform: translate(-50%, -50%);
}

.Viewer-header-text {
  font-size: 48px;
  line-height: 1em;
  font-weight: 100;
}

.Viewer-header-subtitle {
  font-size: 24px;
  font-weight: 100;
  margin-top: -10px;
}

.Viewer-sessions {
  width: 100%;
  padding: 20px;
  max-width: 720px;
  margin: 0 auto;
}

.Viewer-footer {
  text-align: center;
  color: #c79652;
  text-align: center;
  height: 64px;
  padding-top: 24px;
}

.Viewer-live-area {
  height: 136px;
  text-align: center;
  background: #efe1ce;
}

.Viewer-live {
  max-width: 320px;
  margin: 0 auto;
  padding-top: 30px;
}

.Viewer-live-title {
  color: #c79652;
}

.Viewer-live-subtitle {
  color: #bca98f !important;
  font-size: 12px !important;
  text-transform: none !important;
}

