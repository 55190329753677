:root {
  /* Color */
  --color-black: #222;
  --color-greyblack: #999;
  --color-darkblack: #404040;

  --color-yellowlight: #ffe799;
  --color-yellow: #ffc200;
  --color-yellowdark: #c39e22;

  --color-blue: #4d85d1;

  --color-green: #72981b;
  --color-greendark: #424d33;

  --color-white: #fff;

  --color-red: #df2d4f;

  --color-magenta: #8156a7;

  --color-greylight: #e7e8e9;
  --color-greymiddle: #cdcccb;
  --color-greydark: #867979;
}
