.Moderator {
  height: calc(100%);
  padding-top: 40px;
  overflow-y: hidden;
}

.moderatorVideo {
  width: 100%;
  background-color: #000;
  position: relative;
}

.moderatorVideo-height {
  height: 100%;
}

.moderatorVideoIdle {
  width: 100%;
  height: 0;
  background-color: #000;
}

.moderatorVideos {
  width: 100%;
  height: calc(100% - 38px) !important;
  background-color: #000;
}

.subscriberVideo {
  width: 100%;
  background-color: #000;
  position: relative;
}

.subscriberVideo-height {
  height: 100%;
}

.subscriberCounter {
  width: 100%;
  background-color: #000;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.subscriberCounterBlack {
  width: 100%;
  background-color: #000;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.subscriberCounterColored {
  width: 100%;
  background-color: #080;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.subscriberCounter-area {
  transform: translate(0, -50%);
  position: relative;
  top: calc(50%);
}

.subscriberCounter-height {
  height: 100%;
}

.moderatorControls {
  padding: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.moderatorControlsState {
  width: 300px;
  line-height: 30px;
  margin-top: 0px !important;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  color: #db2828; /** #c2ac8e; **/
}

.moderatorControlsEmpty {
  width: 50px;
}

.moderatorControlsIcon {
  font-size: 20px !important;
  color: #f0d1a8;
}

.moderatorStartButton {
  width: 100px;
  height: 30px;
  margin-top: 0px !important;
  font-size: 15px !important;
  font-weight: 500;
  letter-spacing: 0.7px;
  text-transform: uppercase !important;
  color: #fff !important;
}

.moderatorEndLiveButton {
  width: 100px;
  height: 30px;
  margin-top: 0px !important;
  font-size: 15px !important;
  font-weight: 500;
  letter-spacing: 0.7px;
  text-transform: uppercase !important;
  color: #fff !important;
}

.moderatorNoStream {
  height: 240px;
  width: 240px;
  margin: 0 auto;
  top: 50%;
  position: relative;
  transform: translate(0, -50%);
}

.moderatorCounterArea {
  width: 100px;
  height: 45px;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 20%;
  background-color: #080;
  opacity: 0.7;
  z-index: 5000;
  filter: drop-shadow(2px 2px 6px #666);
}

.moderatorCounter {
  margin: auto;
  top: 60%;
  position: relative;
  text-align: center;
  transform: translate(0, -50%);
  color: #fff;
  font-size: 30px;
  font-weight: 700;
}

.moderatorEndButton {
  width: 100%;
  height: 64px;
  position: absolute;
  bottom: 0;
  z-index: 5000;
  border-radius: 50%;
  margin: 0 auto;
    text-align: center;
}

.moderatorNoStreamIcon {
  color: #f0d1a8;
}

.moderatorNoStreamTitle {
  color: #c79655;
}

.moderatorNoStreamSubTitle {
  color: #c2ac8e !important;
  line-height: 20px !important;
}


.moderatorPublicAreaVisible {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.moderatorPrivateAreaVisible {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.moderatorPublicAreaVisible > div {
  flex: 1 1 40%;
}

.moderatorPrivateAreaVisible > div {
  flex: 1 1 40%;
}

@media only screen and (max-width: 640px) {
  .moderatorPublicAreaVisible > div {
      flex: 1 1 100%;
  }

  .moderatorPrivateAreaVisible > div {
    flex: 1 1 100%;
}

  .subscriberCounter-height {
    height: 50%;
  }

  .moderatorVideo-height {
    height: 50%;
  }

  .subscriberVideo-height {
    height: 50%;
  }
}

.moderatorPublicAreaHidden {
  display: none;
}
