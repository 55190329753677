/**
 * Crocks.css
 * Licence MIT
 */

/*  Hidden  */

.d-i-b {
  display: inline-block;
}

.no-display-imp {
  display: none !important;
}

.no-display {
  display: none;
}

.hidden {
  visibility: hidden !important;
}

/*  Text alignment  */
.t-a-l {
  text-align: left;
}

.t-a-r {
  text-align: right;
}

.t-a-c {
  text-align: center;
}

/* Text size  */

.t-s-xs {
  font-size: 12px;
}

.t-s-sm {
  font-size: 16px;
}

.t-s-md {
  font-size: 20px;
}

.t-s-lg {
  font-size: 24px;
}

.t-s-xlg {
  font-size: 28px;
}

/* Content alignment */

.h-full-c {
  margin-top: 50vh;
  transform: translateY(-50%);
}

/* Width, Height */

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

/*  Margin  */

.no-margin {
  margin: 0 !important;
}

.m-xs {
  margin: 8px;
}

.m-sm {
  margin: 16px;
}

.m-t-xxs {
  margin-top: 4px;
}

.m-t-xs {
  margin-top: 8px;
}
.m-t-sm {
  margin-top: 16px;
}

.m-t-md {
  margin-top: 24px;
}

.m-t-ld {
  margin-top: 32px;
}

.m-t-a {
  margin-top: auto;
}

.m-b-xs {
  margin-bottom: 8px;
}

.m-b-md {
  margin-bottom: 24px;
}

.m-b-ld {
  margin-bottom: 32px;
}

.m-b-sm {
  margin-bottom: 16px;
}

.m-b-a {
  margin-bottom: auto;
}

.m-r-xs {
  margin-right: 8px;
}
.m-r-sm {
  margin-right: 16px;
}

.m-r-a {
  margin-right: auto;
}

.m-l-xxs {
  margin-left: 4px;
}

.m-l-xs {
  margin-left: 8px;
}
.m-l-sm {
  margin-left: 16px;
}
.m-l-md {
  margin-left: 24px;
}

.m-l-a {
  margin-left: auto;
}

/*  Padding  */

.no-padding {
  padding: 0 !important;
}

.p-xxs {
  padding: 2px;
}

.p-xs {
  padding: 8px;
}

.p-sm {
  padding: 16px;
}

.p-t-xs {
  padding-top: 8px;
}

.p-t-sm {
  padding-top: 16px;
}

.p-b-xs {
  padding-bottom: 8px;
}

.p-b-sm {
  padding-bottom: 16px;
}

.p-l-0 {
  padding-left: 0px;
}

.p-l-xxs {
  padding-left: 4px;
}

.p-l-xs {
  padding-left: 8px;
}

.p-l-sm {
  padding-left: 16px;
}

.p-r-0 {
  padding-right: 0px;
}

.p-r-xxs {
  padding-right: 4px;
}

.p-r-xs {
  padding-right: 8px;
}

.p-r-sm {
  padding-right: 16px;
}
