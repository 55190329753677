.nostream-text {
  color: white;
  margin: 0 auto;
  top: 50%;
  position: relative;
  transform: translate(-50%, -50%);
}

.nostream {
  aspect-ratio: 480 / 480;
  width: 100%;  
  line-height: 0;      /* setting to 0 prevents the little grey line below the image */
}

.noStream-text {
  aspect-ratio: 480 / 480;
  width: 100%;
}

.nostream-container {
  margin-right: 0 !important;
}

.noStream-image {
  aspect-ratio: 480 / 480;
  object-fit: cover; 
  width: 100%;
}

.noStream-video {
  aspect-ratio: 480 / 480;
  width: 100%;
}
