.Signin {
/*  max-width: 720px; */
  background: #efe1ce;
}

.SigninIconColor {
  color: #c79655;
}

.SigninTitle {
  color: #c79655 !important;
}

.SigninSubElementColor {
  color: #c79655 !important;
}

.SigninButton {
  background-color: #c79655 !important;
  color: #ffffff !important;
}

input {
  border: none !important;
  background-color: #ffffff !important;
  color: #c77955 !important;
}

.input.icon {
  color: #c79655 !important;
}

.SigninForm {
  margin-top: 30px !important;
}
